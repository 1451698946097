<template>
  <!--Dialogs-->
  <v-dialog v-model="uploadDialog" width="500" persistent>
    <form @submit.prevent="upload" enctype="multipart/form-data">
      <v-card>
        <v-card-title>
          Télécharger une base de données
          <v-spacer />
          <v-btn
            icon
            class="v-btn--active"
            color="primary"
            @click="uploadDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          
          <v-file-input
            label="Fichier *"
            show-size
            suffix="Excel"
            @change="uploadFile"
            @focus="uploadErrors && uploadErrors.excel_file ? (uploadErrors.excel_file = '') : false"
            ref="file"
            chips
            :error-messages="uploadErrors.excel_file"
          />
          <v-btn
            color="primary"
            :loading="btnUploadLoading"
            depressed
            class="rounded-lg text-none mt-3"
            large
            type="submit"
          >
            <v-icon left>mdi-upload-outline</v-icon>
            Télécharger
          </v-btn>
        </v-card-text>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      uploadDialog: false,
      btnUploadLoading: false,
      uploadErrors: {},
      form: {
        excel_file: null,
      },
      database_id: null
    };
  },
  methods: {
    open(database_id) {
        this.database_id = database_id;
        this.uploadDialog = true;
    },
    uploadFile(file) {
      this.form.excel_file = file;
    },
    upload() {
      this.btnUploadLoading = true;
      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      HTTP.post("v1/database/importUpdates/"+this.database_id, formData)
        .then(() => {
          this.$successMessage =
            "Mise a jour avec succès";
          this.btnUploadLoading = false;
          this.form = {};
          this.$refs.file.reset();
          this.uploadDialog = false;
          this.database_id = null;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnUploadLoading = false;
          this.uploadErrors = err.response.data.errors;
          console.log(err);
        });
    },
  },
};
</script>