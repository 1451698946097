<template>
  <div>
    <v-card flat class="rounded-lg">
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" exact
                              :disabled="item.disabled">
            <div v-if="item.text === 'Dashboard'"><v-icon size="25" color="primary" style="margin-top: -6px">mdi-home-variant-outline</v-icon></div>
            <div v-else> {{ item.text}}</div>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['breadcrumbs'],
}
</script>
