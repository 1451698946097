<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay"
                 opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col lg="3" cols="12" v-for="(el, i) in 8" :key="i">
            <v-card class="rounded-lg shadow">
              <v-skeleton-loader type="article,actions"></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>

          <v-col cols="12" lg="3" class="d-flex">
            <v-card class="rounded-lg flex flex-column shadow">
              <v-card-title>
                <v-menu
                    v-model="filterMenu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    offset-x
                    max-width="350"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="btn--action ml-2"
                    >
                      <v-icon size="18">mdi-filter-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Filtrages
                      <v-spacer/>
                      <v-btn
                          icon
                          color="primary"
                          @click="filterMenu = false"
                          class="v-btn--active"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <form @submit.prevent="filters">
                        <v-autocomplete
                            v-model="formFilter.ids"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            :items="$store.state.databases"
                            item-value="id"
                            item-text="name"
                            label="Nom"
                            clearable
                        />
                        <v-autocomplete
                            v-model="formFilter.user_ids"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            :items="$store.state.users"
                            item-value="id"
                            :item-text="
                            (item) => {
                              return item.first_name + ' ' + item.last_name;
                            }
                          "
                            label="Utilisateurs"
                            clearable
                        />
                        <v-btn
                            type="submit"
                            color="secondary lighten-1 mt-4"
                            large
                            depressed
                            @click="filterMenu = false"
                            class="rounded-lg text-none"
                        >
                          <v-icon left>mdi-filter-outline</v-icon>
                          Filtrer
                        </v-btn>
                      </form>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-menu
                    v-model="reorderMenu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    offset-x
                    min-width="200"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="btn--action ml-2"
                    >
                      <v-icon size="18">mdi-sort</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Ordonner
                      <v-spacer/>
                      <v-btn
                          icon
                          color="primary"
                          @click="reorderMenu = false"
                          class="v-btn--active"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <v-list dense>
                        <v-list-item-group
                            v-model="order_by"
                            @change="[getDatabases(), (reorderMenu = false)]"
                        >
                          <v-list-item value="name" link>
                            <v-list-item-icon>
                              <v-icon>{{
                                  order_by === "name"
                                      ? "mdi-sort-ascending"
                                      : "mdi-sort-descending"
                                }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Nom</v-list-item-title>
                          </v-list-item>
                          <v-list-item value="created_at" link>
                            <v-list-item-icon>
                              <v-icon>{{
                                  order_by === "created_at"
                                      ? "mdi-sort-ascending"
                                      : "mdi-sort-descending"
                                }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Date</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-card-title>
              <v-card-text class="text-center">

                <v-btn @click="uploadDialog = true"
                       class="v-btn--active"
                       x-large
                       fab
                       text
                       color="primary">
                  <v-icon size="50">mdi-plus-circle</v-icon>
                </v-btn>


                <p class="mt-2">
                  Télécharger une base de données
                </p>

                <v-btn color="gifty"
                       dark
                       depressed
                       class="text-none mt-3"
                       @click="downloadCanvaDialog = true">
                  <v-icon left>mdi-download-outline</v-icon>
                  Telecharger Canvas
                </v-btn>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12"
                 lg="3"
                 class="d-flex"
                 v-if="can('admin')">
            <v-card class="rounded-lg flex flex-column shadow">

              <div class="text-center">
                <v-avatar tile size="100">
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
              </div>

              <v-card-title :style="{fontSize : '17px'}">
                Tous les utilisateurs
              </v-card-title>

              <v-card-subtitle>
                {{ $store.state.user.user.name }}
                <v-spacer/>
                <div class="mt-3">
                  <v-icon class="mt--5" color="gifty">
                    mdi-cloud-sync-outline
                  </v-icon>
                  Mise à jour automatique
                </div>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn color="primary"
                       text
                       depressed
                       block
                       :to="'database/all'">
                  Détail
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12"
                 lg="3"
                 class="d-flex"
                 v-if="can('admin')">
            <v-card class="rounded-lg flex flex-column shadow">
              <div class="text-center">
                <v-avatar tile size="100">
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
              </div>

              <v-card-title :style="{fontSize : '17px'}">
                Client / Fournisseur
              </v-card-title>

              <v-card-subtitle>
                {{ $store.state.user.user.name }}
                <v-spacer/>
                <div class="mt-3">
                  <v-icon class="mt--5" color="gifty">
                    mdi-cloud-sync-outline
                  </v-icon>
                  Mise à jour automatique
                </div>
              </v-card-subtitle>


              <v-card-actions>
                <v-btn block
                       color="primary"
                       text
                       depressed
                       :to="'database/client_fournissseur'"
                >
                  Détail
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12"
                 lg="3"
                 v-for="(el, i) in databases"
                 :key="i"
                 class="d-flex">

            <v-card class="rounded-lg flex flex-column shadow">
              <div class="text-center">
                <v-avatar tile size="100">
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
              </div>

              <v-card-title :style="{fontSize : '17px'}">
                {{ el.name }}
              </v-card-title>

              <v-card-subtitle>
                {{ el.user.name }}
                <v-spacer/>
                <div class="mt-3">
                  <v-icon class="mt--5" color="gifty">
                    mdi-clock-outline
                  </v-icon>
                  {{ el.created_at }}
                </div>
              </v-card-subtitle>

              <v-card-actions>

                <v-chip outlined text-color="gifty">
                  {{ el.salepoints_count }} Utilisateurs
                </v-chip>

                <v-spacer/>
                <v-btn color="primary"
                       text
                       depressed
                       :to="'database/' + el.id">
                  Détail
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                        @click="
                        [
                          (id = el.id),
                          (duplicateNameForm.path = el.path),
                          (duplicateDialog = true),
                        ]
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Dupliquer</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteDatabase(el.id)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Supprimer</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="
                        [
                          (id = el.id),
                          (updateNameForm.name = el.name),
                          (updateNameDialog = true),
                        ]
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Rennomer</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="download(el.path)">
                      <v-list-item-icon>
                        <v-icon>mdi-download-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Télécharger</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ExportExcel(el.id)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-export-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Exporter en excel</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="importUpdates(el.id)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-import-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Importer des données Excel</v-list-item-title>
                    </v-list-item>


                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!--Dialogs-->
      <v-dialog v-model="uploadDialog" width="500" persistent>
        <form @submit.prevent="upload" enctype="multipart/form-data">
          <v-card>
            <v-card-title>
              Télécharger une base de données
              <v-spacer/>
              <v-btn
                  icon
                  class="v-btn--active"
                  color="primary"
                  @click="uploadDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-text-field
                  label="Désignation *"
                  @input="
                  uploadErrors && uploadErrors.name
                    ? (uploadErrors.name = '')
                    : false
                "
                  :error-messages="uploadErrors.name"
                  v-model="uploadForm.name"
              />
              <v-file-input
                  label="Fichier *"
                  show-size
                  suffix="Excel"
                  @change="uploadFile"
                  @focus="
                  uploadErrors && uploadErrors.excel_file
                    ? (uploadErrors.excel_file = '')
                    : false
                "
                  ref="file"
                  chips
                  :error-messages="uploadErrors.excel_file"
              />
              <v-btn
                  color="primary"
                  :loading="btnUploadLoading"
                  depressed
                  class="rounded-lg text-none mt-3"
                  large
                  type="submit"
              >
                <v-icon left>mdi-upload-outline</v-icon>
                Télécharger
              </v-btn>
            </v-card-text>
          </v-card>
        </form>
      </v-dialog>
      <v-dialog v-model="updateNameDialog" width="500" persistent>
        <form @submit.prevent="updateName">
          <v-card>
            <v-card-title>
              Rennomer
              <v-spacer/>
              <v-btn
                  icon
                  class="v-btn--active"
                  color="primary"
                  @click="updateNameDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-text-field
                  label="Désignation *"
                  @input="
                  updateNameErrors && updateNameErrors.name
                    ? (updateNameErrors.name = '')
                    : false
                "
                  :error-messages="updateNameErrors.name"
                  v-model="updateNameForm.name"
              />
              <v-btn
                  color="primary"
                  :loading="btnUpdateLoading"
                  depressed
                  class="rounded-lg text-none mt-3"
                  large
                  type="submit"
              >
                <v-icon left>mdi-contant-save</v-icon>
                Enregistrer
              </v-btn>
            </v-card-text>
          </v-card>
        </form>
      </v-dialog>
      <v-dialog v-model="duplicateDialog" max-width="500" persistent>
        <v-card>
          <form @submit.prevent="duplicateDatabase">
            <v-card>
              <v-card-title>
                Dupliquer
                <v-spacer/>
                <v-btn
                    icon
                    class="v-btn--active"
                    color="primary"
                    @click="duplicateDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <v-text-field
                    label="Désignation *"
                    @input="
                    duplicateNameErrors && duplicateNameErrors.name
                      ? (duplicateNameErrors.name = '')
                      : false
                  "
                    :error-messages="duplicateNameErrors.name"
                    v-model="duplicateNameForm.name"
                />
                <v-btn
                    color="primary"
                    :loading="btnDuplicateLoading"
                    depressed
                    class="rounded-lg text-none mt-3"
                    large
                    type="submit"
                >
                  <v-icon left>mdi-content-duplicate</v-icon>
                  Dupliquer
                </v-btn>
              </v-card-text>
            </v-card>
          </form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="downloadCanvaDialog" max-width="500" persistent>
        <v-card>
          <v-card>
            <v-card-title>
              Télécharger le fichier Canvas
              <v-spacer/>
              <v-btn
                  icon
                  class="v-btn--active"
                  color="primary"
                  @click="downloadCanvaDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="text-center">
              <p>
                Le lorem ipsum est, en imprimerie, une suite de mots sans
                signification utilisée à titre provisoire pour calibrer une mise
                en page.
              </p>
              <v-btn
                  text
                  class="text-none mt-3 rounded-lg mr-2"
                  @click="downloadCanvaDialog = false"
                  large
              >
                <v-icon left>mdi-close</v-icon>
                Fermer
              </v-btn>
              <v-btn
                  color="primary"
                  @click="downloadCanvas"
                  depressed
                  class="rounded-lg text-none mt-3"
                  large
              >
                <v-icon left>mdi-download</v-icon>
                Télécharger
              </v-btn>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>

      <ImportUpdatesVue ref="importUpdates"/>

    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs";
import ImportUpdatesVue from './dialogs/ImportUpdates.vue';

import {HTTP} from "@/http-common";

export default {
  components: {ImportUpdatesVue},

  data() {
    return {
      breadcrumbs: [
        {text: "Dashboard", disabled: false, to: "/"},
        {text: "Base de donnée", disabled: false},
      ],
      reorderMenu: false,
      filterMenu: false,
      overlay: false,
      loading: false,
      downloadCanvaDialog: false,
      btnDuplicateLoading: false,
      btnUploadLoading: false,
      btnUpdateLoading: false,
      uploadDialog: false,
      duplicateDialog: false,
      updateNameDialog: false,
      uploadErrors: {},
      updateNameErrors: {},
      duplicateNameErrors: {},
      uploadForm: {
        excel_file: "",
        name: "",
      },
      updateNameForm: {
        name: "",
      },
      duplicateNameForm: {
        name: "",
        path: "",
      },

      id: "",
      formFilter: {},
      order_by: "",
    };
  },
  methods: {
    uploadFile(file) {
      this.uploadForm.excel_file = file;
    },
    upload() {
      this.btnUploadLoading = true;
      let formData = new FormData();
      Object.keys(this.uploadForm).forEach((key) => {
        formData.append(key, this.uploadForm[key]);
      });
      HTTP.post("/database/import", formData)
          .then(() => {
            this.$successMessage =
                "Cette base de donnée a été enregistrée avec succès";
            this.btnUploadLoading = false;
            this.uploadForm = {};
            this.$refs.file.reset();
            this.uploadDialog = false;
            this.getDatabases();
          })
          .catch((err) => {
            this.btnUploadLoading = false;
            this.uploadErrors = err.response.data.errors;
            console.log(err);
          });
    },
    getDatabases() {
      this.$store.dispatch("fetchDatabases");
    },
    ExportExcel(id) {
      let user = this.$store.getters.getUser;
      let token = user.access_token;
      let DownloadURL =
          process.env.VUE_APP_BASE_URL +
          "v1/database/export/" +
          id +
          "?token=" +
          token +
          "&user_id=" +
          user.user.id;
      window.open(DownloadURL, "_blanc");
    },
    download(path) {
      window.location.href = process.env.VUE_APP_FILE_URL + path;
    },
    downloadCanvas() {
      window.location.href = process.env.VUE_APP_FILE_URL + "/canva_gifty.xlsx";
    },
    deleteDatabase(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    updateName() {
      this.btnUpdateLoading = true;
      HTTP.post("/database/update-name/", {
        database_id: this.id,
        name: this.updateNameForm.name,
      })
          .then(() => {
            this.getDatabases();
            this.$successMessage =
                "Cette base de données a été modifiée avec succès";
            this.btnUpdateLoading = false;
            this.updateNameDialog = false;
            this.refreshStore();
          })
          .catch((err) => {
            this.btnUpdateLoading = false;
            console.log(err);
          });
    },
    duplicateDatabase() {
      this.btnDuplicateLoading = true;
      HTTP.post("/database/duplicate", {
        id: this.id,
        name: this.duplicateNameForm.name,
        path: this.duplicateNameForm.path,
      })
          .then(() => {
            this.$successMessage =
                "Cette base de données a été Dupliqué avec succès";
            this.btnDuplicateLoading = false;
            this.duplicateDialog = false;
            this.getDatabases();
          })
          .catch((err) => {
            this.btnDuplicateLoading = false;
            this.duplicateNameErrors = err.response.data.errors;
            console.log(err);
          });
    },
    removeDatabase() {
      this.$is_confirm = false;
      this.overlay = true;
      HTTP.delete("/database/delete/" + this.id)
          .then(() => {
            let i = this.databases.findIndex((item) => item.id === this.id);
            this.$delete(this.databases, i);
            this.$successMessage =
                "Cette base de données a été supprimée avec succès";
            this.overlay = false;
            this.refreshStore();
          })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },

    importUpdates(database_id) {
      this.$refs.importUpdates.open(database_id);
    }
  },
  created() {
    this.getDatabases();
  },
  computed: {
    databases() {
      return this.$store.getters.getDatabases;
    },
    isLoading() {


      return this.$store.getters.getLoading;
    },
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.removeDatabase();
      }
    },
  },
};
</script>

<style scoped></style>
